if ('serviceWorker' in navigator) {
  console.log('Service workers unregister command!');
  navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
            for(let registration of registrations) {
                console.log('Service worker unregistered.');
                registration.unregister();
            }
        })
        .catch(function(error) {
            console.error('Error unregistering service worker:', error);
        });
}
